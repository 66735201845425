import React, { useEffect } from "react";

import { Section } from "../index";
import { IframeWrapper } from "./styles";

export const GoogleReviews = ({
  id,
  title,
  sectionSubtitle,
  hideLineSeparation,
  script,
  displaySetting

}) => {
  const wrapperId = `googleReviewWrapper${id}`;

  useEffect(() => {
    const wrapperElement = document.getElementById(wrapperId);
    const scriptElement = document.createElement("script");
    scriptElement.src = `https://cdn.trustindex.io/loader.js?${script}`;
    wrapperElement.appendChild(scriptElement);
  }, []);

  return (
    <Section
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <IframeWrapper id={`${wrapperId}`}></IframeWrapper>
    </Section>
  );
};

export default GoogleReviews;
