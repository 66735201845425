import styled from "styled-components";

export const IframeWrapper = styled.div`
  .ti-reviews-container-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ti-review-item {
    padding: 0 0 0 16px !important;
  }
`;
